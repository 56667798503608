
import Vue from 'vue';
import { mapGetters } from 'vuex';
import G6, { Graph, GraphData, IShape } from '@antv/g6';

import { getManifest, hasManifest } from 'Models/assetsManifest';
import { makeColor } from 'Lib/utils/hexColorMaker';
import AccountColorMarker from 'Common/AccountColorMarker.vue';
import { getRoutes } from 'Store/v2/Defi';
import ApiError from 'Entities/ApiError';
import Account from 'Entities/privatePresenter/Account';
import Icon from 'UI/Icon.vue';
import { IRouteEdge } from 'Entities/dex/RouteEdge';
import { IRouteNode } from 'Entities/dex/RouteNode';

interface Data {
    graphData: null | { nodes: IRouteNode[]; edges: IRouteEdge[]; };
    graphInstance: null | Graph;
}

interface Methods {
    getRoutes: () => void;
    closeBlock: () => void;
    initializeGraph: (data: { nodesBackground: string; textColor: string; }) => void;
}

interface Computed {
    activeAccount: Account;
    isThemeDark: boolean;
    activeAccountColor: string;
    fromQuantityTruncated: string;
    toQuantityTruncated: string;
    nodesBackground: string;
    textColor: string;
    percentsBackground: string;
}

export default Vue.extend<Data, Methods, Computed, any>({
    components: {
        AccountColorMarker,
        Icon,
    },
    props: {
        blockchainName: {
            type: String,
            required: true,
        },
        fromAssetSymbol: {
            type: String,
            required: true,
        },
        placementTag: {
            type: String,
            required: true,
        },
        fromQuantity: {
            type: String,
            required: true,
        },
        toQuantity: {
            type: String,
            required: true,
        },
        toAssetSymbol: {
            type: String,
            required: true,
        },
        gasPrice: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            graphData: null,
            graphInstance: null,
        };
    },
    computed: {
        ...mapGetters({
            activeAccount: 'Accounts/activeAccount',
            isThemeDark: 'isThemeDark',
        }),
        activeAccountColor() {
            if (!this.activeAccount || !this.activeAccount.color) {
                return this.isThemeDark ? '#23232A' : '#f1f2f5';
            }
            return this.activeAccount.color;
        },
        fromQuantityTruncated() {
            return Number(this.fromQuantity).toFixed(8).noExponents();
        },
        toQuantityTruncated() {
            return Number(this.toQuantity).toFixed(8).noExponents();
        },
        nodesBackground() {
            return this.isThemeDark ? '#2E2E37' : '#fff';
        },
        textColor() {
            return this.isThemeDark ? '#fff' : '#23244D';
        },
        percentsBackground() {
            return this.isThemeDark ? '#23244d80' : '#F1F2F5';
        },
    },
    methods: {
        async getRoutes() {
            try {
                const data = await this.$store.dispatch(getRoutes({
                    fromAssetSymbol: this.fromAssetSymbol,
                    quantity: this.fromQuantity,
                    toAssetSymbol: this.toAssetSymbol,
                    placementTag: this.placementTag,
                    blockchainName: this.blockchainName,
                    gasPrice: this.gasPrice,
                }));
                this.graphData = data.serialize();
            } catch (error) {
                if (error instanceof ApiError) {
                    await this.$store.dispatch('Notificator/showErrorNotification', error.data ? error.data.message : 'Error during getting routes');
                    this.closeBlock();
                }
            }
        },
        closeBlock() {
            this.$emit('close');
        },
        initializeGraph({ nodesBackground, textColor }) {
            G6.registerNode('root', {
                draw(cfg, group) {
                    if (!group) {
                        return {} as IShape;
                    }
                    const {
                        assetSymbol = '',
                    } = cfg as any;
                    const rectConfig = {
                        width: 150,
                        height: 60,
                        fill: nodesBackground,
                        radius: 12,
                        stroke: nodesBackground,
                    };
                    const textConfig = {
                        textAlign: 'left' as 'start' | 'end' | 'left' | 'center' | 'right' | undefined,
                        textBaseline: 'top' as 'top' | 'hanging' | 'middle' | 'alphabetic' | 'ideographic' | 'bottom' | undefined,
                    };
                    const rect = group.addShape('rect', {
                        attrs: {
                            x: 0,
                            y: 0,
                            ...rectConfig,
                        },
                    });
                    try {
                        group.addShape('image', {
                            attrs: {
                                x: 6,
                                y: 8,
                                width: 14,
                                height: 14,
                                img: require(`@/assets/images/icons/cryptoicons/${assetSymbol.toLowerCase()}.svg`),
                                cursor: 'default',
                            },
                        });
                    } catch (error) {
                        group.addShape('circle', {
                            attrs: {
                                x: 14,
                                y: 14,
                                r: 8,
                                fill: hasManifest(assetSymbol) ? getManifest(assetSymbol).color : makeColor(assetSymbol),
                            },
                        });
                        group.addShape('text', {
                            attrs: {
                                ...textConfig,
                                x: 10,
                                y: 8,
                                text: assetSymbol[0],
                                fontWeight: 400,
                                fontSize: 12,
                                lineHeight: 16,
                                fill: '#fff',
                                cursor: 'default',
                            },
                        });
                    }
                    group.addShape('text', {
                        attrs: {
                            ...textConfig,
                            x: 24,
                            y: 8,
                            text: assetSymbol,
                            fontWeight: 600,
                            fontSize: 14,
                            lineHeight: 19,
                            fill: textColor,
                            cursor: 'default',
                        },
                    });
                    group.addShape('rect', {
                        attrs: {
                            x: 0,
                            y: 28,
                            height: 1,
                            width: 150,
                            fill: '#928fa833',
                            stroke: '#928fa833',
                        },
                    });
                    group.addShape('image', {
                        attrs: {
                            x: 6,
                            y: 36,
                            width: 12,
                            height: 12,
                            img: require('@/assets/images/icons/exchanges/singlebroker.svg'),
                            cursor: 'default',
                        },
                    });
                    group.addShape('text', {
                        attrs: {
                            ...textConfig,
                            x: 24,
                            y: 36,
                            text: 'Single Broker',
                            fontWeight: 600,
                            fontSize: 12,
                            lineHeight: 16,
                            fill: textColor,
                            cursor: 'default',
                        },
                    });
                    return rect;
                },
            }, 'rect');
            G6.registerNode('node', {
                draw(cfg, group) {
                    if (!group) {
                        return {} as IShape;
                    }
                    const {
                        assetSymbol = '',
                        exchanges = [],
                    } = cfg as any;
                    const rectConfig = {
                        width: 150,
                        height: 60 + (exchanges.length - 1) * 12,
                        fill: nodesBackground,
                        radius: 12,
                        stroke: nodesBackground,
                    };
                    const textConfig = {
                        textAlign: 'left' as 'start' | 'end' | 'left' | 'center' | 'right' | undefined,
                        textBaseline: 'top' as 'top' | 'hanging' | 'middle' | 'alphabetic' | 'ideographic' | 'bottom' | undefined,
                    };
                    const rect = group.addShape('rect', {
                        attrs: {
                            x: 0,
                            y: 0,
                            ...rectConfig,
                        },
                    });
                    try {
                        group.addShape('image', {
                            attrs: {
                                x: 6,
                                y: 8,
                                width: 14,
                                height: 14,
                                img: require(`@/assets/images/icons/cryptoicons/${assetSymbol.toLowerCase()}.svg`),
                                cursor: 'default',
                            },
                        });
                    } catch (error) {
                        group.addShape('circle', {
                            attrs: {
                                x: 14,
                                y: 14,
                                r: 8,
                                fill: hasManifest(assetSymbol) ? getManifest(assetSymbol).color : makeColor(assetSymbol),
                            },
                        });
                        group.addShape('text', {
                            attrs: {
                                ...textConfig,
                                x: 10,
                                y: 8,
                                text: assetSymbol[0],
                                fontWeight: 400,
                                fontSize: 12,
                                lineHeight: 16,
                                fill: '#fff',
                                cursor: 'default',
                            },
                        });
                    }
                    group.addShape('text', {
                        attrs: {
                            ...textConfig,
                            x: 24,
                            y: 8,
                            text: assetSymbol.substring(0, 2) === '0x' ? '0x...' : assetSymbol,
                            fontWeight: 600,
                            fontSize: 14,
                            lineHeight: 19,
                            fill: textColor,
                            cursor: 'default',
                        },
                    });
                    group.addShape('rect', {
                        attrs: {
                            x: 0,
                            y: 28,
                            height: 1,
                            width: 150,
                            fill: '#928fa833',
                            stroke: '#928fa833',
                        },
                    });
                    exchanges.forEach((exchange, index) => {
                        let hasImage = true;
                        try {
                            group.addShape('image', {
                                attrs: {
                                    x: 6,
                                    y: 36 + (14 * index),
                                    width: 12,
                                    height: 12,
                                    img: require(`@/assets/images/icons/exchanges/${exchange.placementName.toLowerCase()}.svg`),
                                    cursor: 'default',
                                },
                            });
                        } catch (error) {
                            hasImage = false;
                        }
                        const truncateString = (str) => {
                            if (str.length > 16) {
                                return `${str.substring(0, 15)}...`;
                            }
                            return str;
                        };
                        group.addShape('text', {
                            attrs: {
                                ...textConfig,
                                x: hasImage ? 24 : 6,
                                y: 36 + (14 * index),
                                text: truncateString(exchange.placementName),
                                fontWeight: 600,
                                fontSize: 12,
                                lineHeight: 16,
                                fill: textColor,
                                cursor: 'default',
                            },
                        });
                    });
                    return rect;
                },
            }, 'rect');

            const graph = new G6.Graph({
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                container: this.$refs.graph,
                width: 800,
                height: 800,
                fitView: true,
                defaultEdge: {
                    type: 'cubic-horizontal',
                    style: {
                        stroke: '#656FDB',
                        endArrow: { path: G6.Arrow.circle(2, 0), fill: '#656FDB' },
                        startArrow: { path: G6.Arrow.circle(2, 0), fill: '#656FDB' },
                    },
                },
                layout: {
                    type: 'dagre',
                    rankdir: 'LR',
                },
            });
            graph.data(this.graphData as GraphData);
            graph.render();
            this.graphInstance = graph;
        },
    },
    async mounted() {
        await this.getRoutes();
        this.initializeGraph({ nodesBackground: this.nodesBackground, textColor: this.textColor });
    },
    watch: {
        isThemeDark() {
            if (this.graphInstance) {
                this.graphInstance.destroy();
            }
            this.initializeGraph({ nodesBackground: this.nodesBackground, textColor: this.textColor });
        },
    },
});
