<template>
    <svg width="10" height="17" viewBox="0 0 10 17" stroke="none" fill="currentColor" xmlns="http://www.w3.org/2000/svg"  v-bind:class="this.className">
        <path d="M5 1V15M5 15L1 11M5 15L9 11" stroke="#758DEF" fill="none" stroke-width="2" stroke-linecap="round"/>
    </svg>
</template>

<script>
export default {
    props: ['className'],
    name: 'ArrowDown',
};
</script>
