
import Vue from 'vue';

import Icon from 'UI/Icon.vue';
import { PlacementCapsules, PlacementsWithCapsules, TRANSACTIONS_STATUSES } from 'Store/v2/Defi';
import theme from 'Theme';
import { copyTextWithPermissions } from 'Lib/utils/copyTextWithPermissions';

interface Data {
    showedAdditionalRow: boolean;
    TRANSACTIONS_STATUSES: any;
    theme: any;
    PlacementsWithCapsules: any;
    PlacementCapsules: any;
}

interface Methods {
    blockchainNativeSymbol: (data: string) => string;
    tableDateFormatter: (date: string) => string;
    noExponentialNumber: (data: number) => string;
    goToLink: () => void;
    copy: (data: string) => void;
}

interface Computed {
    txHashLink: string;
}

export default Vue.extend<Data, Methods, Computed, any>({
    components: {
        Icon,
    },
    props: {
        transaction: {
            type: Object,
            required: true,
        },
        blockchains: {
            type: Array,
            required: true,
        },
    },
    data() {
        return {
            showedAdditionalRow: false,
            TRANSACTIONS_STATUSES,
            theme,
            PlacementsWithCapsules,
            PlacementCapsules,
        };
    },
    computed: {
        txHashLink() {
            if (this.transaction.blockchainName.toLowerCase() === 'ethereum') {
                return `https://etherscan.io/tx/${this.transaction.txHash}`;
            }
            return `https://bscscan.com/tx/${this.transaction.txHash}`;
        },
    },
    methods: {
        goToLink() {
            window.open(this.txHashLink);
        },
        async copy(value) {
            await copyTextWithPermissions(value, this.$store.dispatch, 'Transaction ID');
        },
        blockchainNativeSymbol(blockchainName: string) {
            const blockchain = this.blockchains!.find((b) => b.name === blockchainName);
            return blockchain ? blockchain.nativeAssetSymbol : '';
        },
        tableDateFormatter(date: string) {
            const startDate = new Date(date).toDateString();
            const dateComponentsArray = startDate.split(' ');
            const month = dateComponentsArray[1];
            const day = dateComponentsArray[2];
            const year = dateComponentsArray[3];
            const time = new Date(date).toTimeString().split(' ')[0];
            return `${day} ${month} ${year}, ${time}`;
        },
        noExponentialNumber(num: number | string, precision?: number) {
            if (typeof num === 'string') {
                num = Number(num);
            }
            if (precision) {
                num = Number(num.toFixed(precision));
            }
            let result = '0.';
            const initialString = String(num);
            const firstPart = initialString.split('e')[0];
            const secondPart = initialString.split('e')[1];
            if (!secondPart) {
                return String(num);
            }
            const integerPart = firstPart.split('.')[0];
            const floatPart = firstPart.split('.')[1];
            if (floatPart) {
                const zeros = (-1 * Number(secondPart)) - integerPart.length;
                for (let i = 1; i <= zeros; i += 1) {
                    result += '0';
                }
                result += integerPart + floatPart;
            } else {
                const zeros = (-1 * Number(secondPart));
                for (let i = 1; i <= zeros; i += 1) {
                    result += '0';
                }
                result += integerPart;
            }
            return result;
        },
    },
});
