
import Vue from 'vue';
import { mapGetters } from 'vuex';

import Placement from 'Entities/publicPresenter/Placement';
import DexPosition from 'Entities/privatePresenter/DexPosition';
import BlockHeader from 'UI/BlockHeader.vue';
import NumberFormater from 'UI/NumberFormater.vue';
import Icon from 'UI/Icon.vue';
import {
    MANAGE_LIQUIDITY_TYPES,
    getDexPoolsPositions,
    getPoolInfo,
    SET_A_ASSET,
    SET_B_ASSET,
    SET_MANAGE_LIQUIDITY_TYPE,
    SET_NETWORK,
    SET_POOL_FEE,
    setWrappedAssets,
    SET_PLACEMENT,
    SET_HAS_OPENED_POSITION,
} from 'Store/v2/DefiLiquidityPools';
import { calculatePrecision } from 'Lib/utils/quotationAssetPrecisionCalculator';
import ApiError from 'Entities/ApiError';
import InternalUserResponse from 'Entities/userLoginHistory/InternalUserResponse';
import theme from 'Theme';
import { changeScroll } from 'Lib/utils/horizontalScroll';
import { PlacementCapsules, PlacementsWithCapsules } from 'Store/v2/Defi';

interface Data {
    activePlacementName: string;
    calculatePrecision: any;
    MANAGE_LIQUIDITY_TYPES: any;
    theme: any;
    PlacementCapsules: any;
    PlacementsWithCapsules: any;
}

interface Methods {
    setActivePlacement: (data: string, needEmit: boolean) => void;
    positionSummaryQuotation: (data: DexPosition) => number;
    positionFirstAssetQuotation: (data: DexPosition) => number;
    positionSecondAssetQuotation: (data: DexPosition) => number;
    addLiquidity: (data: DexPosition) => void;
    removeLiquidity: (data: DexPosition) => void;
    pooledFirstAssetQuotation: (data: DexPosition) => number;
    pooledSecondAssetQuotation: (data: DexPosition) => number;
    quotedPNL: (data: DexPosition) => string;
    PNLSign: (data: DexPosition) => boolean | undefined;
    onWheel: (e: WheelEvent) => void;
    getIsPlacementDisabled: (placementName: string) => boolean;
}

interface Computed {
    placementsList: Placement[];
    poolsPositions: DexPosition[];
    activePlacementPositions: DexPosition[];
    placementsBalances: Map<string, number>;
    quotationAssetPrecision: number;
    activeAccountId: string;
    quotationAssetSymbol: string;
    quotationAssetCharacter: string;
    summaryPlacementBalances: number;
    currentUser: InternalUserResponse | undefined;
    isKycVerified: boolean;
}

export default Vue.extend<Data, Methods, Computed>({
    components: {
        BlockHeader,
        NumberFormater,
        Icon,
    },
    data() {
        return {
            activePlacementName: '',
            calculatePrecision,
            MANAGE_LIQUIDITY_TYPES,
            theme,
            PlacementCapsules,
            PlacementsWithCapsules,
        };
    },
    computed: {
        ...mapGetters({
            activeAccountId: 'Accounts/activeAccountID',
            quotationAssetSymbol: 'Assets/GET_QUOTATION_ASSET_SYMBOL',
            quotationAssetCharacter: 'Assets/GET_QUOTATION_ASSET_CHARACTER',
        }),

        currentUser() {
            return this.$store.state.User.currentUser;
        },
        isKycVerified() {
            if (!this.currentUser) {
                return false;
            }
            return this.currentUser.kycStatus === 'Verified';
        },
        placementsList() {
            return this.$store.state.Defi.placements;
        },
        poolsPositions() {
            return this.$store.state.DefiLiquidityPools.poolsPositions;
        },
        activePlacementPositions() {
            return this.poolsPositions.filter((p) => p.placementName === this.activePlacementName);
        },
        placementsBalances() {
            const balancesMap = new Map<string, number>();
            this.poolsPositions.forEach((p) => {
                const quotation = p.quotations?.find((q) => q.asset?.symbol === this.quotationAssetSymbol);
                if (balancesMap.has(p.placementName)) {
                    balancesMap.set(p.placementName, balancesMap.get(p.placementName)! + (quotation ? quotation.aQuantity + quotation.bQuantity : 0));
                } else {
                    balancesMap.set(p.placementName, (quotation ? quotation.aQuantity + quotation.bQuantity : 0));
                }
            });
            return balancesMap;
        },
        summaryPlacementBalances() {
            const keys = this.placementsBalances.keys();
            let accumulator = 0;
            if (this.placementsBalances) {
                Array.from(keys).forEach((k) => {
                    accumulator += this.placementsBalances.get(k)!;
                });
            }
            return accumulator;
        },
        quotationAssetPrecision() {
            return this.calculatePrecision(this.quotationAssetSymbol);
        },
    },
    methods: {
        setActivePlacement(placementName, needEmit) {
            if (this.getIsPlacementDisabled(placementName)) {
                return;
            }

            this.activePlacementName = placementName;
            if (needEmit) {
                this.$emit('select', placementName);
            }
        },
        positionSummaryQuotation(position) {
            const quotation = position.quotations?.find((q) => q.asset?.symbol === this.quotationAssetSymbol);
            if (!quotation) {
                return 0;
            }
            return Number((quotation.aQuantity + quotation.bQuantity).toFixed(this.quotationAssetPrecision));
        },
        positionFirstAssetQuotation(position) {
            const quotation = position.quotations?.find((q) => q.asset?.symbol === this.quotationAssetSymbol);
            return quotation ? Number(quotation.aQuantity.toFixed(this.quotationAssetPrecision)) : 0;
        },
        positionSecondAssetQuotation(position) {
            const quotation = position.quotations?.find((q) => q.asset?.symbol === this.quotationAssetSymbol);
            return quotation ? Number(quotation.bQuantity.toFixed(this.quotationAssetPrecision)) : 0;
        },
        async addLiquidity(position) {
            if (!this.isKycVerified) {
                return;
            }
            try {
                this.$store.commit(SET_MANAGE_LIQUIDITY_TYPE(MANAGE_LIQUIDITY_TYPES.add));
                this.$store.commit(SET_NETWORK(position.blockchainName));
                this.$store.commit(SET_PLACEMENT(position.placementName));
                this.$store.commit(SET_A_ASSET(position.aAssetSymbol));
                this.$store.commit(SET_B_ASSET(position.bAssetSymbol));
                this.$store.commit(SET_POOL_FEE(position.placementCommissionRate));
                this.$store.commit(SET_HAS_OPENED_POSITION(true));
                await this.$store.dispatch(getPoolInfo(undefined));
                await this.$store.dispatch(setWrappedAssets(undefined));
                await this.$router.push('/Defi/ManageLiquidity');
            } catch (error) {
                if (error instanceof ApiError) {
                    await this.$store.dispatch('Notificator/showErrorNotification', error.data ? error.data.message : 'Error during getting pool info, please try again later');
                }
            }
        },
        async removeLiquidity(position) {
            if (!this.isKycVerified) {
                return;
            }
            try {
                this.$store.commit(SET_MANAGE_LIQUIDITY_TYPE(MANAGE_LIQUIDITY_TYPES.remove));
                this.$store.commit(SET_NETWORK(position.blockchainName));
                this.$store.commit(SET_PLACEMENT(position.placementName));
                this.$store.commit(SET_A_ASSET(position.aAssetSymbol));
                this.$store.commit(SET_B_ASSET(position.bAssetSymbol));
                this.$store.commit(SET_POOL_FEE(position.placementCommissionRate));
                this.$store.commit(SET_HAS_OPENED_POSITION(true));
                await this.$store.dispatch(getPoolInfo(undefined));
                await this.$store.dispatch(setWrappedAssets(undefined));
                await this.$router.push('/Defi/ManageLiquidity');
            } catch (error) {
                if (error instanceof ApiError) {
                    await this.$store.dispatch('Notificator/showErrorNotification', error.data ? error.data.message : 'Error during getting pool info, please try again later');
                }
            }
        },
        pooledFirstAssetQuotation(position) {
            const quotation = position.quotations?.find((q) => q.asset?.symbol === this.quotationAssetSymbol);
            return quotation ? Number(quotation.aInvestedQuantity.toFixed(this.quotationAssetPrecision)) : 0;
        },
        pooledSecondAssetQuotation(position) {
            const quotation = position.quotations?.find((q) => q.asset?.symbol === this.quotationAssetSymbol);
            return quotation ? Number(quotation.bInvestedQuantity.toFixed(this.quotationAssetPrecision)) : 0;
        },
        quotedPNL(position) {
            const quotation = position.quotations?.find((q) => q.asset?.symbol === this.quotationAssetSymbol);
            return quotation ? `${this.quotationAssetCharacter}${quotation.pnl >= 0 ? Number(quotation.pnl.toFixed(this.quotationAssetPrecision)) : Number((quotation.pnl * (-1)).toFixed(this.quotationAssetPrecision))}` : `${this.quotationAssetCharacter}0`;
        },
        PNLSign(position) {
            const quotation = position.quotations?.find((q) => q.asset?.symbol === this.quotationAssetSymbol);
            if (!quotation) {
                return undefined;
            }
            if (Number(quotation.pnl.toFixed(2)) > 0) {
                return true;
            }
            if (Number(quotation.pnl.toFixed(2)) < 0) {
                return false;
            }
            if (Number(quotation.pnl.toFixed(2)) === 0) {
                return undefined;
            }
        },
        onWheel(e) {
            changeScroll(e, this.$refs.grid as Element);
        },
        getIsPlacementDisabled(placementName) {
            return !this.$store.state.Placements.maintenanceStatuses.get(placementName);
        },
    },
    async created() {
        await this.$store.dispatch(getDexPoolsPositions(undefined));
    },
    watch: {
        async activeAccountId() {
            await this.$store.dispatch(getDexPoolsPositions(undefined));
        },
        placementsList(placements) {
            // TODO: later change to 0 index (костыль, пока не работает маркетдата для Pancake Swap)
            const firstActivePlacementName = placements.find(({ name }) => name === 'SushiSwap')?.name ?? placements[0].name;
            this.activePlacementName = firstActivePlacementName;
        },
    },
});
