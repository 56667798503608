
import Vue from 'vue';
import { debounce as deb } from 'vue-debounce';
import { mapGetters } from 'vuex';
import { maxValue, minValue } from 'vuelidate/dist/validators.min';

import numberFormater from 'Mixins/numberFormater';
import MoneyInput from 'Control/MoneyInput.vue';
import CryptoIcon from 'Components/common/CryptoIcon.vue';
import ArrowDown from 'Components/icons/ArrowDown.vue';
import Asset from 'Entities/publicPresenter/Asset';
import Blockchain from 'Entities/publicPresenter/Blockchain';
import Placement from 'Entities/publicPresenter/Placement';
import Balance from 'Entities/privatePresenter/Balance';
import layoutNameResolver from 'Mixins/layoutNameResolver';
import {
    approve,
    checkCurrencyApprove,
    checkQuantityApprove,
    DefiUI,
    getQuotes,
    init,
    SET_UI,
    swap,
    updateDexTransactions,
    CHECK_APPROVAL_STATUSES,
    getNetworkFee,
    clearNetworkFeeInterval,
    SET_QUOTE,
    PlacementCapsules,
    PlacementsWithCapsules, getBlockchains,
} from 'Store/v2/Defi';
import DexTransaction from 'Entities/privatePresenter/DexTransaction';
import { SET_QUOTATIONS } from 'Store/v2/TradingData';
import PageLayout from 'Common/PageLayout.vue';
import { gridPages } from 'Models/interface';
import DexTransactionsTableRow from 'Components/Defi/components/DexTransactionsTableRow.vue';
import LiquidityPoolsTableRow from 'Components/Defi/components/LiquidityPoolsTableRow.vue';
import PublicDataApi from 'Apis/PublicData';
import AssetQuotationsRequest from 'Entities/publicPresenter/AssetQuotationsRequest';
import AssetQuotation from 'Entities/publicPresenter/AssetQuotation';
import { SET_LOADING_OFF, SET_LOADING_ON } from 'Store/v2/Preloader';
import { Account } from 'Models/accounts';
import GetAllowanceResponse from 'Entities/dex/GetAllowanceResponse';
import CheckApprovalResponse from 'Entities/dex/CheckApprovalResponse';
import Quote from 'Entities/dex/Quote';
import {
    getPoolsList,
    updatePoolsListPage,
    SORTING_COLUMNS,
    SORTING_TYPES,
    SET_SORTING,
    searchPools,
} from 'Store/v2/DefiLiquidityPools';
import DexPoolInfo from 'Entities/publicPresenter/DexPoolInfo';
import AccountPortfolio from 'Modules/AccountPortfolio/AccountPortfolio.vue';
import LiquidityPoolsPositions from 'Components/Defi/components/LiquidityPoolsPositions.vue';
import Routing from 'Components/Defi/components/Routing.vue';
import ApiError from 'Entities/ApiError';
import InternalUserResponse from 'Entities/userLoginHistory/InternalUserResponse';
import { composedPath } from 'Lib/utils/eventPathChecker';
import theme from 'Theme';
import BlockHeader from 'UI/BlockHeader.vue';
import Icon from 'UI/Icon.vue';
import ModuleBlocker from 'UI/ModuleBlocker.vue';
import Button from 'Control/Button.vue';
import NoData from 'UI/NoData.vue';
import Pagination from 'Control/Pagination.vue';
import DeFiApi from 'Apis/DeFi';
import QuoteParams from 'Lib/entities/dex/QuoteParams';

const debounce: any = deb((callback: any) => callback(), 300);

interface Data {
    theme: typeof theme,
    step: 'placement' | 'swap';
    fromValue: number;
    currencyApprove: GetAllowanceResponse | null;
    quantityApprove: CheckApprovalResponse | null;
    loading: boolean;
    showSelect: boolean;
    currentBreakpoint: string;
    slippage: number;
    hasError: boolean;
    usdQuotation: AssetQuotation[] | undefined;
    isQuotesLoading: boolean;
    accountId: string | undefined;
    isSwapping: boolean;
    isApproving: boolean;
    currentPage: number;
    currentLiquidityPoolsPage: number;
    slippageButtons: any[];
    feeButtons: any[];
    sellButtons: any[];
    errorText: string;
    poolTableSorting: any;
    SORTING_TYPES: any;
    SORTING_COLUMNS: any;
    poolInputValue: string;
    isPoolSearchActive: boolean;
    CHECK_APPROVAL_STATUSES: any;
    selectedFeeIndex: number;
    selectedQuantityIndex: number;
    areExchangesPending: boolean;
    showRouting: boolean;
    routingProps: { blockchainName: string; fromAssetSymbol: string; placementTag: string; fromQuantity: string; toQuantity: string; toAssetSymbol: string; };
    quotesInfo: Quote[];
    isDefiMenuVisible: boolean;
    PlacementCapsules: any;
    PlacementsWithCapsules: any;
    showTooltip: boolean;
}

type Computed = {
    slippageErrorText: string;
    fromValueErrorText: string;
    placements: Placement[];
    blockchains: Blockchain[] | undefined;
    ui: DefiUI;
    assets: Asset[];
    balances: Balance[];
    currentBalance: { index: number; balance: Balance | undefined };
    quotes: Quote[];
    price: string;
    minimumReceived: string;
    fee: string;
    transactions: DexTransaction[];
    layout: { get: () => any, set: (value: any) => any };
    quotedTVLHeader: number;
    quotedVolume24hHeader: number;
    quotedVolume30dHeader: number;
    liquidityPoolsHeader: number;
    activeAccountId: string;
    hasDefiAccess: boolean;
    totalPages: number;
    totalLiquidityPoolsPages: number;
    activeAccount: Account;
    outputBalance: Balance | undefined;
    gasPrice: number | undefined;
    currentPlacement: undefined | string;
    currentBlockchain: undefined | string;
    currentAssetFrom: undefined | string;
    currentAssetTo: undefined | string;
    liquidityPools: DexPoolInfo[];
    currentBlockchainNativeAssetSymbol: string;
    searchedPoolsList: DexPoolInfo[];
    feeButtonsMaker: { title: string, callback: () => void }[];
    activeAccountColor: string | undefined;
    isThemeDark: boolean;
    currentUser: InternalUserResponse | undefined;
    isKycVerified: boolean;
    availableInput: Balance | undefined;
    isRoutingNeeded: boolean;
    filteredAssets: Asset[];
    isNativeTokenSwapping: boolean;
    networkFee: string;
    isApproved: boolean;
    networkFeeTimer: number;
};

type Methods = {
    setPlacement: (placement: string) => void;
    setBlockchain: (blockchain: string) => void;
    handleFromAsset: (data: string) => void;
    handleToAsset: (b: string) => void;
    getQuotes: () => void;
    setQuote: (q: Quote) => void;
    approve: () => void;
    swap: () => void;
    checkCurrencyApprove: () => void;
    checkQuantityApprove: () => void;
    breakpointChangedEvent: (breakpoint: any) => void;
    blockchainNativeSymbol: (data: string) => string;
    tableDateFormatter: (data: string) => string;
    setSlippage: (data: number) => void;
    setSellValue: (data: number) => void;
    noExponentialNumber: (data: number) => string;
    setSorting: (data: string) => void;
    openRouting: () => void;
    closeRouting: () => void;
    clearForm: () => void;
    toggleShowSelect: () => void;
    onSearch: (data: string) => void;
    getIsPlacementAvailable: (placementName: string) => boolean;
    scrollListener: () => void;
};

export default Vue.extend<Data, Methods, Computed>({
    components: {
        LiquidityPoolsPositions,
        AccountPortfolio,
        PageLayout,
        MoneyInput,
        CryptoIcon,
        ArrowDown,
        DexTransactionsTableRow,
        LiquidityPoolsTableRow,
        BlockHeader,
        Icon,
        Routing,
        ModuleBlocker,
        Button,
        NoData,
        Pagination,
    },
    mixins: [
        numberFormater,
        layoutNameResolver,
    ],
    data() {
        return {
            theme,
            console,
            SORTING_TYPES,
            SORTING_COLUMNS,
            CHECK_APPROVAL_STATUSES,
            showRouting: false,
            step: 'placement',
            fromValue: 0,
            currencyApprove: null,
            quantityApprove: null,
            loading: false,
            showSelect: false,
            currentBreakpoint: 'lg',
            slippage: 0,
            hasError: false,
            errorText: '',
            usdQuotation: undefined,
            isQuotesLoading: false,
            accountId: undefined,
            isSwapping: false,
            isApproving: false,
            currentPage: 1,
            currentLiquidityPoolsPage: 1,
            selectedFeeIndex: 0,
            selectedQuantityIndex: 0,
            areExchangesPending: false,
            quotesInfo: [],
            isDefiMenuVisible: true,
            showTooltip: true,
            poolTableSorting: {
                [SORTING_COLUMNS.volume24h]: SORTING_TYPES.desc,
                [SORTING_COLUMNS.lpFee24h]: SORTING_TYPES.none,
                [SORTING_COLUMNS.apr7d]: SORTING_TYPES.none,
                [SORTING_COLUMNS.roiUSD7d]: SORTING_TYPES.none,
                [SORTING_COLUMNS.roiUSD30d]: SORTING_TYPES.none,
                [SORTING_COLUMNS.roiUSD365d]: SORTING_TYPES.none,
                [SORTING_COLUMNS.txCount24h]: SORTING_TYPES.none,
                [SORTING_COLUMNS.txCount7d]: SORTING_TYPES.none,
                [SORTING_COLUMNS.reserve]: SORTING_TYPES.none,
            },
            poolInputValue: '',
            isPoolSearchActive: false,
            routingProps: {
                blockchainName: '',
                fromAssetSymbol: '',
                placementTag: '',
                fromQuantity: '',
                toQuantity: '',
                toAssetSymbol: '',
            },
            PlacementCapsules,
            PlacementsWithCapsules,

            slippageButtons: [
                {
                    title: '1%',
                    callback: () => (this as any).setSlippage(1),
                },
                {
                    title: '2%',
                    callback: () => (this as any).setSlippage(2),
                },
                {
                    title: '3%',
                    callback: () => (this as any).setSlippage(3),
                },
                {
                    title: '5%',
                    callback: () => (this as any).setSlippage(5),
                },
                {
                    title: 'Max',
                    callback: () => (this as any).setSlippage(49.99),
                },
            ],
            feeButtons: [
                {
                    title: 'Auto',
                    callback: () => true,
                },
                {
                    title: '0.05%',
                    callback: () => true,
                    disabled: true,
                },
                {
                    title: '0.3%',
                    callback: () => true,
                    disabled: true,
                },
                {
                    title: '1%',
                    callback: () => true,
                    disabled: true,
                },
            ],
            sellButtons: [
                {
                    title: '10%',
                    callback: () => (this as any).setSellValue(0.1),
                },
                {
                    title: '25%',
                    callback: () => (this as any).setSellValue(0.25),
                },
                {
                    title: '50%',
                    callback: () => (this as any).setSellValue(0.5),
                },
                {
                    title: 'Max',
                    callback: () => (this as any).setSellValue(1),
                },
            ],
        };
    },
    validations() {
        return {
            slippage: {
                maxValue: maxValue(49.99),
                minValue: minValue(0.1),
            },
            fromValue: {
                maxValue: maxValue(this.availableInput?.free ?? 0),
            },
        };
    },
    computed: {
        ...mapGetters({
            vueGridLayoutBreakpoints: 'vueGridLayoutBreakpoints',
            vueGridLayoutCols: 'vueGridLayoutCols',
            quotationSymbol: 'Assets/GET_QUOTATION_ASSET_SYMBOL',
            activeAccount: 'Accounts/activeAccount',
            isThemeDark: 'isThemeDark',
        }),
        fromValueErrorText() {
            if (!this.$v.fromValue.maxValue) {
                return `Sell amount must be lower than ${this.availableInput?.free ?? 0}`;
            }
            return '';
        },
        slippageErrorText() {
            if (!this.$v.slippage.maxValue) {
                return `Sell amount must be lower than ${49.99}`;
            }
            if (!this.$v.slippage.minValue) {
                return `Sell amount must be lower than ${0.1}`;
            }
            return '';
        },
        feeButtonsMaker() {
            const { quotes } = this;
            if (quotes.length < 2) {
                return [];
            }
            let result: { title: string, callback: () => void }[] = [];
            result = quotes.map((q, index) => {
                return {
                    title: `${q.fee}%`,
                    callback: () => {
                        this.selectedFeeIndex = index;
                        this.selectedQuantityIndex = index;
                    },
                };
            });
            return result;
        },

        currentUser() {
            return this.$store.state.User.currentUser;
        },
        isKycVerified() {
            if (!this.currentUser) {
                return false;
            }
            return this.currentUser.kycStatus === 'Verified';
        },
        activeAccountColor() {
            if (!this.activeAccount || !this.activeAccount.color) {
                return this.isThemeDark ? '#23232A' : '#f1f2f5';
            }
            return this.activeAccount.color;
        },
        currentBlockchainNativeAssetSymbol() {
            const defi = this.$store.state.Defi;
            const currentBlockchain = defi.blockchains?.find((b) => b.name === defi.ui.blockchain);
            if (!currentBlockchain) {
                return '';
            }
            return currentBlockchain.nativeAssetSymbol;
        },
        liquidityPools() {
            return this.$store.state.DefiLiquidityPools.poolsList;
        },
        gasPrice() {
            const defi = this.$store.state.Defi;
            return defi.gasPrice;
        },
        placements() {
            return this.$store.state.Defi.placements || [];
        },
        blockchains() {
            return this.$store.state.Defi.blockchains || [];
        },
        ui() {
            return this.$store.state.Defi.ui;
        },
        assets() {
            return this.$store.state.Defi.assets || [];
        },
        balances() {
            return this.$store.state.Defi.fromBalances?.filter(({ blockchainName }) => blockchainName === this.ui.blockchain) ?? [];
        },
        currentBalance() {
            const balanceIndex = this.balances?.findIndex((p) => p.assetSymbol === this.ui.fromAsset);
            if (typeof balanceIndex === 'number' && balanceIndex !== -1) {
                return { index: balanceIndex, balance: this.balances![balanceIndex] };
            }
            return { index: -1, balance: undefined };
        },
        availableInput() {
            return this.balances?.find((b) => b.assetSymbol === this.ui.fromAsset);
        },
        outputBalance() {
            return this.balances?.find((b) => b.assetSymbol === this.ui.toAsset);
        },
        quotes() {
            return this.$store.state.Defi.quotes || [];
        },
        price() {
            if (this.quotes && this.quotes.length > 0) {
                const value = this.fromValue || 0;
                let price = String((value - (value * this.ui.slippage) / 100) / this.quotes[this.selectedQuantityIndex].quantity!);
                while (price.endsWith('0')) {
                    price = price.slice(0, -1);
                }
                return `~${Number(price).toFixed(8).noExponents()}`;
            }
            return '...';
        },
        minimumReceived() {
            if (this.quotes && this.quotes.length > 0) {
                const { quantity: value, fee } = this.quotes[this.selectedQuantityIndex];
                let price: string = (value! - ((value! * this.ui.slippage) / 100) - ((value! * fee!) / 100)).toFixed(8);
                while (price.endsWith('0')) {
                    price = price.slice(0, -1);
                }
                return `${Number(price).toFixed(8)}`;
            }
            return '...';
        },
        fee() {
            if (this.quotes && this.quotes.length > 0) {
                const value = this.fromValue || 0;
                const { fee } = this.quotes[this.selectedQuantityIndex];
                let feeValue: string = ((value * fee!) / 100).toFixed(8);
                while (feeValue.endsWith('0')) {
                    feeValue = feeValue.slice(0, -1);
                }
                return `${Number(feeValue).toFixed(8)}`;
            }
            return '...';
        },
        transactions() {
            return this.$store.state.Defi.dexTransactions;
        },
        layout: {
            get() {
                return this.$store.getters.pageGrid(gridPages.DEFI, this.currentBreakpoint);
            },
            set(grid) {
                this.$store.dispatch('changePageGrid', { page: gridPages.DEFI, grid, breakpoint: this.currentBreakpoint });
            },
        },
        quotedTVLHeader() {
            const defiState = this.$store.state.Defi;
            const currentMarketData = defiState.marketData?.find((md) => md.placementName.toUpperCase() === defiState.ui.placement.toUpperCase());
            if (this.usdQuotation && currentMarketData) {
                const quotationAssetSymbol = this.$store.getters['Assets/GET_QUOTATION_ASSET_SYMBOL'];
                const quotation = this.usdQuotation.find((q) => q.assetPairSymbol.split('/')[1] === quotationAssetSymbol);
                const rate = quotation ? quotation.rate : 0;
                return Number(currentMarketData.tvl) * rate;
            }
            return 0;
        },
        quotedVolume24hHeader() {
            const defiState = this.$store.state.Defi;
            const currentMarketData = defiState.marketData?.find((md) => md.placementName.toUpperCase() === defiState.ui.placement.toUpperCase());
            if (this.usdQuotation && currentMarketData) {
                const quotationAssetSymbol = this.$store.getters['Assets/GET_QUOTATION_ASSET_SYMBOL'];
                const quotation = this.usdQuotation.find((q) => q.assetPairSymbol.split('/')[1] === quotationAssetSymbol);
                const rate = quotation ? quotation.rate : 0;
                return Number(currentMarketData.volume24h) * rate;
            }
            return 0;
        },
        quotedVolume30dHeader() {
            const defiState = this.$store.state.Defi;
            const currentMarketData = defiState.marketData?.find((md) => md.placementName.toUpperCase() === defiState.ui.placement.toUpperCase());
            if (this.usdQuotation && currentMarketData) {
                const quotationAssetSymbol = this.$store.getters['Assets/GET_QUOTATION_ASSET_SYMBOL'];
                const quotation = this.usdQuotation.find((q) => q.assetPairSymbol.split('/')[1] === quotationAssetSymbol);
                const rate = quotation ? quotation.rate : 0;
                return Number(currentMarketData.volume30d) * rate;
            }
            return 0;
        },
        liquidityPoolsHeader() {
            const defiState = this.$store.state.Defi;
            const currentMarketData = defiState.marketData?.find((md) => md.placementName.toUpperCase() === defiState.ui.placement.toUpperCase());
            if (currentMarketData) {
                return currentMarketData.poolCount;
            }
            return 0;
        },
        activeAccountId() {
            return this.$store.getters['Accounts/activeAccountID'];
        },
        hasDefiAccess() {
            if (this.activeAccount) {
                return this.activeAccount.policies.includes('defi');
            }
            return true;
        },
        totalPages() {
            const totalPages = this.$store.state.Defi.ui.transactionsPagination.totalPage;
            return totalPages === -1 ? 0 : totalPages as number;
        },
        totalLiquidityPoolsPages() {
            const totalPages = this.$store.state.DefiLiquidityPools.ui.totalPoolsListPages;
            return totalPages ?? 0;
        },
        currentAssetFrom() {
            return this.$store.state.Defi.ui.fromAsset;
        },
        currentAssetTo() {
            return this.$store.state.Defi.ui.toAsset;
        },
        currentPlacement() {
            return this.$store.state.Defi.ui.placement;
        },
        currentBlockchain() {
            return this.$store.state.Defi.ui.blockchain;
        },
        searchedPoolsList() {
            return this.$store.state.DefiLiquidityPools.searchedPoolsList;
        },
        isRoutingNeeded() {
            return this.$store.state.Defi.ui.placement.toUpperCase() === '1INCH' && this.fromValue !== 0;
        },
        filteredAssets() {
            return this.assets.filter(({ symbol }) => symbol !== this.currentAssetFrom);
        },
        isNativeTokenSwapping() {
            return this.currentAssetFrom === this.blockchainNativeSymbol(this.currentBlockchain ?? '');
        },
        networkFee() {
            const fee = this.$store.state.Defi.ui.networkFee.value;
            return fee
                ? `${fee.toFixed(8).noExponents().getSeparatedDigits()}`
                : '...';
        },
        isApproved() {
            return this.$store.state.Defi.ui.isApproved;
        },
        networkFeeTimer() {
            return this.$store.state.Defi.ui.networkFee.timer;
        },
    },
    methods: {
        scrollListener() {
            this.isDefiMenuVisible = document.querySelector('body')!.scrollTop < 40;
            if (!this.isDefiMenuVisible) {
                (this.$refs.defiMenu as any)!.style.left = `${document.querySelector('aside')!.getBoundingClientRect().width + 14}px`;
            }
        },
        noExponentialNumber(num) {
            let result = '0.';
            const initialString = String(num);
            const firstPart = initialString.split('e')[0];
            const secondPart = initialString.split('e')[1];
            if (!secondPart) {
                return String(num);
            }
            const integerPart = firstPart.split('.')[0];
            const floatPart = firstPart.split('.')[1];
            if (floatPart) {
                const zeros = (-1 * Number(secondPart)) - integerPart.length;
                for (let i = 1; i <= zeros; i += 1) {
                    result += '0';
                }
                result += integerPart + floatPart;
            } else {
                const zeros = (-1 * Number(secondPart));
                for (let i = 1; i <= zeros; i += 1) {
                    result += '0';
                }
                result += integerPart;
            }
            return result;
        },
        async setPlacement(name) {
            if (!this.getIsPlacementAvailable(name)) {
                return;
            }

            this.$store.commit(SET_UI({ placement: name }));
            (this.$refs.PoolsPositions as any).setActivePlacement(name, false);
            this.fromValue = 0;
            if (this.hasDefiAccess) {
                try {
                    this.areExchangesPending = true;
                    await this.$store.dispatch(getBlockchains(undefined));
                    if ((this.blockchains?.length ?? 0) <= 1) {
                        this.showSelect = false;
                    }
                    await this.$store.dispatch(init({ withoutHistory: true, withoutBlockchain: true }));
                } finally {
                    this.areExchangesPending = false;
                }
            }
            await this.checkCurrencyApprove();
        },
        async setBlockchain(name) {
            this.showSelect = false;
            this.$store.commit(SET_UI({ blockchain: name }));
            this.fromValue = 0;
            if (this.hasDefiAccess) {
                try {
                    this.areExchangesPending = true;
                    await this.$store.dispatch(init({ withoutHistory: true, withoutBlockchain: true }));
                } finally {
                    this.areExchangesPending = false;
                }
            }
            await this.checkCurrencyApprove();
        },
        async handleFromAsset(assetSymbol: string) {
            this.fromValue = 0;
            this.$store.commit(SET_UI({ fromAsset: assetSymbol }));
            const { assets } = this.$store.state.Defi;
            const { toAsset } = this.$store.state.Defi.ui;
            if (this.currentAssetFrom === toAsset) {
                const index = assets?.findIndex((a) => a.symbol === toAsset);
                if (typeof index === 'number' && index !== -1) {
                    if (index !== assets!.length - 1) {
                        this.handleToAsset(assets![index + 1].symbol);
                    } else {
                        this.handleToAsset(assets![index - 1].symbol);
                    }
                }
            }
            this.$store.commit(SET_QUOTATIONS([]));
            await this.checkCurrencyApprove();
        },
        handleToAsset(symbol) {
            this.fromValue = 0;
            this.$store.commit(SET_UI({ toAsset: symbol }));
        },
        async getQuotes() {
            if (this.fromValue) {
                this.isQuotesLoading = true;
                await this.$store.dispatch(getQuotes(Number(this.fromValue)));
                await this.$store.dispatch(getNetworkFee({
                    isAutoReload: false,
                    aAssetSymbol: this.currentAssetFrom ?? '',
                    bAssetSymbol: this.currentAssetTo ?? '',
                    placementName: this.currentPlacement ?? '',
                    blockchain: this.currentBlockchain ?? '',
                    quantity: String(this.fromValue),
                }));
                this.isQuotesLoading = false;
            }
        },
        setQuote({ fee }) {
            const index = this.quotes?.findIndex((q) => q.fee === fee);
            this.$store.commit(SET_UI({ quoteIndex: index }));
        },
        async approve() {
            if (this.fromValue) {
                try {
                    this.isApproving = true;
                    this.$store.commit(SET_LOADING_ON(undefined));
                    await this.$store.dispatch(approve(this.noExponentialNumber(Number(this.fromValue))));
                    await this.checkQuantityApprove();
                } catch (error) {
                    if (error instanceof ApiError) {
                        await this.$store.dispatch('Notificator/showErrorNotification', error.data ? error.data.message : 'Error during approve, please try again later');
                    }
                } finally {
                    this.$store.commit(SET_LOADING_OFF(undefined));
                    this.isApproving = false;
                    this.clearForm();
                }
            }
        },
        async swap() {
            setTimeout(() => {
                this.showTooltip = true;
            }, 100);

            this.$v.$touch();
            if (this.$v.$invalid) {
                return;
            }

            if (this.slippage > 49.99 || this.slippage < 0.1) {
                this.hasError = true;
                this.errorText = 'Slippage must be between 0.1% and 49.99%';
            } else {
                this.hasError = false;
                this.errorText = '';
                if (this.fromValue) {
                    try {
                        this.isSwapping = true;
                        this.$store.commit(SET_LOADING_ON(undefined));
                        await this.$store.dispatch(swap({ amount: this.noExponentialNumber(Number(this.fromValue)), fee: String(this.quotes[this.selectedFeeIndex].fee) }));
                    } catch (error) {
                        if (error instanceof ApiError) {
                            await this.$store.dispatch('Notificator/showErrorNotification', error.data ? error.data.message : 'Error during approve, please try again later');
                        }
                    } finally {
                        this.$store.commit(SET_LOADING_OFF(undefined));
                        this.isSwapping = false;
                        this.clearForm();
                    }
                }
            }
        },
        async checkCurrencyApprove() {
            this.loading = true;
            this.currencyApprove = null;
            try {
                const data: GetAllowanceResponse = await this.$store.dispatch(checkCurrencyApprove(undefined));
                this.currencyApprove = data;
            } catch (error) {
                await this.$store.dispatch('Notificator/showErrorNotification', 'Invalid Input Data');
            }
            this.loading = false;
        },
        async checkQuantityApprove() {
            if (this.fromValue) {
                this.loading = true;
                this.quantityApprove = null;
                try {
                    const data: CheckApprovalResponse = await this.$store.dispatch(checkQuantityApprove(this.fromValue.toString()));
                    this.quantityApprove = data;
                } catch (error) {
                    await this.$store.dispatch('Notificator/showErrorNotification', 'Invalid Input Data');
                }
                this.loading = false;
            }
        },
        breakpointChangedEvent(breakpoint) {
            this.currentBreakpoint = breakpoint;
            this.$store.commit('SET_PAGE_ACTIVE_BREAKPOINT', { page: gridPages.DEFI, breakpoint });
        },
        blockchainNativeSymbol(blockchainName) {
            const blockchain = this.blockchains!.find((b) => b.name === blockchainName);
            return blockchain ? blockchain.nativeAssetSymbol : '';
        },
        tableDateFormatter(date) {
            const startDate = new Date(date).toDateString();
            const dateComponentsArray = startDate.split(' ');
            const month = dateComponentsArray[1];
            const day = dateComponentsArray[2];
            const year = dateComponentsArray[3];
            const time = new Date(date).toTimeString().split(' ')[0];
            return `${day} ${month} ${year}, ${time}`;
        },
        setSlippage(num) {
            this.slippage = num;
        },
        setSellValue(multiplier) {
            if (multiplier === 1 && this.quotesInfo.length) {
                // если пользователь хочет свапить нативный токен сети в размере 100% своего баланса - вычитать из суммы свапа предзапрошеную комиссию
                let temp: number;
                temp = Number((multiplier * this.currentBalance!.balance!.free).floor(18));
                const maxCommission = Math.max(...this.quotesInfo.map(({ fee }) => Number(fee)));
                temp -= temp * 0.01 * maxCommission;
                temp -= this.ui.networkFee.value;
                if (temp < 0) {
                    temp = 0;
                }
                this.fromValue = temp;
            } else {
                this.fromValue = Number((multiplier * this.currentBalance!.balance!.free).floor(18));
            }
        },
        async setSorting(column) {
            if (this.isPoolSearchActive) {
                return;
            }
            // eslint-disable-next-line guard-for-in,no-restricted-syntax
            for (const key in this.poolTableSorting) {
                if (key !== column) {
                    this.poolTableSorting[key] = this.SORTING_TYPES.none;
                }
            }
            if (this.poolTableSorting[column] === SORTING_TYPES.none) {
                this.poolTableSorting[column] = SORTING_TYPES.desc;
            } else if (this.poolTableSorting[column] === SORTING_TYPES.asc) {
                this.poolTableSorting[column] = SORTING_TYPES.desc;
            } else if (this.poolTableSorting[column] === SORTING_TYPES.desc) {
                this.poolTableSorting[column] = SORTING_TYPES.asc;
            }
            this.$store.commit(SET_SORTING({ column, type: this.poolTableSorting[column] }));
            await this.$store.dispatch(updatePoolsListPage({ page: this.currentLiquidityPoolsPage, placementName: this.currentPlacement ?? '', blockchainName: this.currentBlockchain ?? '' }));
        },
        openRouting() {
            const { placements } = this.$store.state.Defi;
            const { ui } = this.$store.state.Defi;

            const placementTag = placements.find(({ name }) => name.toUpperCase() === ui.placement.toUpperCase())?.tag;
            const blockchainName = ui.blockchain;
            const fromAssetSymbol = ui.fromAsset;
            const quantity = String(this.fromValue);
            const toAssetSymbol = ui.toAsset;
            this.routingProps = {
                placementTag: placementTag ?? '',
                blockchainName: blockchainName ?? '',
                fromAssetSymbol: fromAssetSymbol ?? '',
                fromQuantity: quantity ?? '',
                toQuantity: String(this.quotes[this.selectedQuantityIndex].quantity) ?? '',
                toAssetSymbol: toAssetSymbol ?? '',
            };
            this.showRouting = true;
        },
        closeRouting() {
            this.showRouting = false;
        },
        async clearForm() {
            this.fromValue = 0;
            (this.$refs.sellInput as any).cleanFocusButtons();
            this.$store.commit(SET_QUOTE([]));
            await this.$store.dispatch(clearNetworkFeeInterval(undefined));
        },
        toggleShowSelect() {
            this.showSelect = !this.showSelect;
        },
        onSearch(e) {
            this.poolInputValue = e;
        },
        getIsPlacementAvailable(placementName) {
            return this.$store.state.Placements.maintenanceStatuses.get(placementName);
        },
    },
    async created() {
        if (this.hasDefiAccess) {
            try {
                this.areExchangesPending = true;
                await this.$store.dispatch(init({ withoutHistory: false, withoutBlockchain: false }));
            } finally {
                this.areExchangesPending = false;
            }
            if (this.activeAccountId) {
                await this.checkCurrencyApprove();
            }
        }
        const { data: usdQuotation } = await PublicDataApi.publicGetAssetQuotations(new AssetQuotationsRequest({
            assetSymbols: ['USD'],
        }));
        this.usdQuotation = usdQuotation;

        // hide select on click outside
        document.addEventListener('click', (event) => {
            let flag = true;
            const path = composedPath(event.target);
            if (path) {
                path.forEach((el) => {
                    if (el.classList && Array.from(el.classList).includes('dropdown-element')) {
                        flag = false;
                    }
                });
                if (flag) {
                    this.showSelect = false;
                }
            }
        });
    },
    async mounted() {
        (this.$refs.slippageInput as any).runButtonCallback(this.slippageButtons[0].callback, 0, this.slippageButtons[0]);
        document.querySelector('body')!.addEventListener('scroll', this.scrollListener);
        document.addEventListener('click', () => {
            this.showTooltip = false;
        });
        await this.$store.dispatch(getPoolsList({ placementName: this.currentPlacement ?? '', blockchainName: this.currentBlockchain ?? '' }));
    },
    async beforeDestroy() {
        document.querySelector('body')!.removeEventListener('scroll', this.scrollListener);
        await this.$store.dispatch(clearNetworkFeeInterval(undefined));
    },
    watch: {
        async currentAssetFrom() {
            await this.clearForm();
        },
        async currentAssetTo() {
            await this.clearForm();
        },
        quotes() {
            this.selectedQuantityIndex = 0;
            this.selectedFeeIndex = 0;
        },
        fromValue() {
            debounce(() => {
                this.getQuotes();
                this.checkQuantityApprove();
            });
            this.$v.fromValue.$reset();
        },
        slippage(val) {
            this.$store.commit(SET_UI({ slippage: val }));
            this.hasError = false;
            this.errorText = '';
            this.$v.slippage.$reset();
        },
        async activeAccountId(newVal) {
            this.fromValue = 0;
            if (this.hasDefiAccess) {
                try {
                    this.areExchangesPending = true;
                    await this.$store.dispatch(init({ withoutHistory: true, withoutBlockchain: false }));
                } finally {
                    this.areExchangesPending = false;
                }
                await this.checkCurrencyApprove();
            }
            this.accountId = newVal;
            await this.clearForm();
        },
        async transactions() {
            await this.checkQuantityApprove();
        },
        async currentPage(val) {
            try {
                this.$store.commit(SET_LOADING_ON(undefined));
                await this.$store.dispatch(updateDexTransactions(val));
            } finally {
                this.$store.commit(SET_LOADING_OFF(undefined));
            }
        },
        async currentPlacement() {
            this.currentLiquidityPoolsPage = 1;
            this.poolInputValue = '';
            await this.clearForm();
            await this.$store.dispatch(getPoolsList({ placementName: this.currentPlacement ?? '', blockchainName: this.currentBlockchain ?? '' }));
        },
        async currentBlockchain() {
            this.currentLiquidityPoolsPage = 1;
            this.poolInputValue = '';
            await this.clearForm();
            await this.$store.dispatch(getPoolsList({ placementName: this.currentPlacement ?? '', blockchainName: this.currentBlockchain ?? '' }));
        },
        async currentLiquidityPoolsPage(page) {
            await this.$store.dispatch(updatePoolsListPage({ page, placementName: this.currentPlacement ?? '', blockchainName: this.currentBlockchain ?? '' }));
        },
        async poolInputValue(value) {
            if (value.length < 2) {
                this.isPoolSearchActive = false;
                return;
            }
            this.currentLiquidityPoolsPage = 1;
            // eslint-disable-next-line guard-for-in,no-restricted-syntax
            for (const key in this.poolTableSorting) {
                this.poolTableSorting[key] = this.SORTING_TYPES.none;
            }
            this.$store.commit(SET_SORTING({ column: '', type: '' }));
            this.isPoolSearchActive = true;
            await this.$store.dispatch(searchPools({ placementName: this.currentPlacement ?? '', blockchainName: this.currentBlockchain ?? '', inputValue: value }));
        },
        showRouting() {
            document.getElementById('swap')!.classList.add('flipBlock');
            setTimeout(() => {
                document.getElementById('swap')!.classList.remove('flipBlock');
            }, 500);
        },
        async isNativeTokenSwapping(value) {
            if (value) {
                try {
                    const { data: quotes } = await DeFiApi.privateDexGetQuotes(new QuoteParams({
                        aAssetSymbol: this.ui.fromAsset,
                        bAssetSymbol: this.ui.toAsset,
                        placementName: this.ui.placement,
                        blockchainName: this.ui.blockchain,
                        quantity: '0.01',
                    }));
                    this.quotesInfo = quotes;
                } catch {
                    this.quotesInfo = [];
                }
            } else {
                this.quotesInfo = [];
            }
        },
    },
});
